import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ChainId } from '@uniswap/sdk-core'
import { ReactComponent as ArbIcon } from 'assets/svg/toast/arb_bg.svg'
import { ReactComponent as BlastIcon } from 'assets/svg/toast/blast_bg.svg'
import { ReactComponent as CloseIcon } from 'assets/svg/toast/close.svg'
import { useOpenOffchainActivityModal } from 'components/AccountDrawer/MiniPortfolio/Activity/OffchainActivityModal'
import { signatureToActivity, transactionToActivity } from 'components/AccountDrawer/MiniPortfolio/Activity/parseLocal'
import { Activity } from 'components/AccountDrawer/MiniPortfolio/Activity/types'
import { PortfolioLogo } from 'components/AccountDrawer/MiniPortfolio/PortfolioLogo'
import PortfolioRow from 'components/AccountDrawer/MiniPortfolio/PortfolioRow'
import Column, { AutoColumn } from 'components/Column'
import AlertTriangleFilled from 'components/Icons/AlertTriangleFilled'
import Row, { AutoRow } from 'components/Row'
import { getChainInfo } from 'constants/chainInfo'
import { TransactionStatus } from 'graphql/data/__generated__/types-and-hooks'
import { useAllTokensMultichain } from 'hooks/Tokens'
import useENSName from 'hooks/useENSName'
import { useOrder } from 'state/signatures/hooks'
import { useTransaction } from 'state/transactions/hooks'
import styled from 'styled-components/macro'
import { EllipsisStyle, ThemedText } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

export const StyledClose = styled(CloseIcon)`
  position: absolute;
  right: -8px;
  top: -8px;
  z-index: 999;

  :hover {
    cursor: pointer;
  }
`

const PopupRight = styled.div`
  width: 8px;
  height: 100%;
  border-radius: 8px 0 0 8px;
`

export const PopupInfoRight = styled(PopupRight)`
  background: ${({ theme }) => theme.claimBtn};
`

export const PopupErrorRight = styled(PopupRight)`
  background: ${({ theme }) => theme.short};
`

const PopupContainer = styled.div<{ padded?: boolean }>`
  position: relative;
  width: 100%;
  min-height: 100px;
  background-color: ${({ theme }) => theme.backgroundModule};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.deepShadow};
  transition: ${({ theme }) => `visibility ${theme.transition.duration.fast} ease-in-out`};

  display: flex;
  align-items: center;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
  min-width: 290px;
  &:not(:last-of-type) {
    margin-right: 20px;
  }
`}
`

export const ArbChainIcon = styled(ArbIcon)`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 99;
`

export const BlastChainIcon = styled(BlastIcon)`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 99;
`

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

const ColumnContainer = styled(AutoColumn)`
  margin: 0 12px;
`

export const PopupAlertTriangle = styled(AlertTriangleFilled)`
  flex-shrink: 0;
  width: 48px;
  height: 48px;
`

export function FailedNetworkSwitchPopup({ chainId, onClose }: { chainId: ChainId; onClose: () => void }) {
  const chainInfo = getChainInfo(chainId)

  return (
    <PopupContainer padded>
      <StyledClose onClick={onClose} />
      {chainId == 42161 ? <ArbChainIcon /> : <BlastChainIcon />}
      <Row gap="8px">
        <PopupErrorRight />
        <RowNoFlex gap="12px">
          <PopupAlertTriangle />
          <ColumnContainer gap="sm">
            <ThemedText.SubHeader color="textSecondary">
              <Trans>Failed to switch networks</Trans>
            </ThemedText.SubHeader>

            <ThemedText.BodySmall color="textSecondary">
              <Trans>To use Uniswap on {chainInfo.label}, switch the network in your wallet’s settings.</Trans>
            </ThemedText.BodySmall>
          </ColumnContainer>
        </RowNoFlex>
      </Row>
    </PopupContainer>
  )
}

const Descriptor = styled(ThemedText.BodySmall)`
  ${EllipsisStyle}
`

type ActivityPopupContentProps = { activity: Activity; onClick: () => void; onClose: () => void }
function ActivityPopupContent({ activity, onClick, onClose }: ActivityPopupContentProps) {
  const success = activity.status === TransactionStatus.Confirmed
  const { ENSName } = useENSName(activity?.otherAccount)
  return (
    <PopupContainer>
      <StyledClose onClick={onClose} />
      {activity.chainId == 42161 ? <ArbChainIcon /> : <BlastChainIcon />}
      <Box mr="8px" height="100%">
        {success ? <PopupInfoRight /> : <PopupErrorRight />}
      </Box>
      <PortfolioRow
        left={
          success ? (
            <Column>
              <PortfolioLogo
                chainId={activity.chainId}
                currencies={activity.currencies}
                images={activity.logos}
                accountAddress={activity.otherAccount}
              />
            </Column>
          ) : (
            <PopupAlertTriangle />
          )
        }
        title={
          <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
            {activity.title}
          </ThemedText.TextPrimary>
        }
        descriptor={
          <ThemedText.TextSecondary fontSize={14}>
            {activity.descriptor}
            {ENSName ?? activity.otherAccount}
          </ThemedText.TextSecondary>
        }
        onClick={onClick}
      />
    </PopupContainer>
  )
}

export function TransactionPopupContent({
  chainId,
  hash,
  onClose,
}: {
  chainId: ChainId
  hash: string
  onClose: () => void
}) {
  const transaction = useTransaction(hash)
  const tokens = useAllTokensMultichain()

  if (!transaction) return null

  const activity = transactionToActivity(transaction, chainId, tokens)
  if (!activity) return null

  const onClick = () =>
    window.open(getExplorerLink(activity.chainId, activity.hash, ExplorerDataType.TRANSACTION), '_blank')

  return <ActivityPopupContent activity={activity} onClose={onClose} onClick={onClick} />
}

export function UniswapXOrderPopupContent({ orderHash, onClose }: { orderHash: string; onClose: () => void }) {
  const order = useOrder(orderHash)
  const tokens = useAllTokensMultichain()
  const openOffchainActivityModal = useOpenOffchainActivityModal()
  if (!order) return null

  const activity = signatureToActivity(order, tokens)

  if (!activity) return null

  const onClick = () => openOffchainActivityModal({ orderHash, status: order.status })

  return <ActivityPopupContent activity={activity} onClose={onClose} onClick={onClick} />
}
